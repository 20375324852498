import palette from "../../theme/palette/index";
import { styled } from "@mui/system";
import { Form } from "formik";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export const SResponseVacancyForm = styled(Form)({
  marginBottom: 0,

  "& .inputPhone": {
    maxWidth: "unset",
    marginTop: 37,
  },
});

export const SBtnSend = styled(Button)({
  "&.MuiButtonBase-root.MuiButton-root": {
    backgroundColor: palette.purpleBlue,
    color: palette.secondary,
    width: 264,
    height: 60,
    borderRadius: 8,
    marginTop: 32,
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
    border: `1px solid ${palette.purpleBlue} `,

    "&:hover": {
      backgroundColor: palette.secondary,
      color: palette.purpleBlue,
    },

    "@media (max-width: 640px)": {
      margin: "26px 0px",
    },

    "@media (max-width: 575px)": {
      width: "100%",
    },
  },
});

export const SNameEmailWrapper = styled(Box)({
  display: "flex",
  width: "100%",

  "@media (max-width: 640px)": {
    flexDirection: "column",
  },

  "& > div": {
    marginTop: 37,
  },
});

export const SBtnUpload = styled(Button)({
  "&.MuiButtonBase-root.MuiButton-root": {
    maxWidth: 380,
    width: "100%",
    height: 52,
    backgroundColor: palette.porcelain,
    color: palette.primary,
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "21px",
    textTransform: "unset",
    padding: "0px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "none",
    marginTop: 23,

    "& svg": {
      width: 15,
      height: 16,
    },

    "@media (max-width: 575px)": {
      width: "100%",
      maxWidth: "unset",
    },
  },
});

export const SAddResume = styled(Box)({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "26px",
  letterSpacing: "0.15px",
  marginTop: 50,
});
