import React from "react";

const Upload = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 14.3158H15V16H0V14.3158ZM8.33333 3.22358V12.6316H6.66667V3.22358L1.6075 8.33684L0.429167 7.14611L7.5 0L14.5708 7.14526L13.3925 8.336L8.33333 3.22526V3.22358Z"
        fill="#09121F"
      />
    </svg>
  );
};

export default Upload;
