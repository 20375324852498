import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/system";
import palette from "../../theme/palette";

export const SDialog = styled(Dialog)({
  "& .MuiPaper-root.MuiDialog-paper": {
    margin: "22px 16px",
    padding: 60,
    maxWidth: 783,
    width: "100%",
    overflowY: "unset",
    borderRadius: 12,

    "@media (max-width: 735px)": {
      padding: 20,
    },
  },

  "& .MuiTypography-root.MuiDialogTitle-root": {
    fontSize: 48,
    fontWeight: 600,
    lineHeight: "72px",
    letterSpacing: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 0,

    "@media (max-width: 575px)": {
      fontSize: 35,
      lineHeight: "45px",
    },
  },

  "& .MuiButtonBase-root.MuiIconButton-root": {
    padding: 0,
    background: palette.secondary,
    width: 60,
    height: 60,
    margin: "-90px auto 30px auto",
    boxShadow: "0px 8px 18px -6px #18274B1F",

    "@media (max-width: 735px)": {
      margin: "-50px auto 15px auto",
    },
  },

  "& .MuiDialogContent-root": {
    padding: 0,
  },
});
