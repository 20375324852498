import * as yup from "yup";

export const messages = {
  PROMPT_MAIL: "Enter your email",
  REQUIRE_MESSAGE: "Required field",
  INCORRECT_SYMBOL: "Forbidden symbol",
};

export const schemaResponseVacancy = yup.object().shape({
  name: yup
      .string(messages.INCORRECT_SYMBOL)
      .required(messages.REQUIRE_MESSAGE),
  email: yup
    .string(messages.PROMPT_MAIL)
    .email(messages.PROMPT_MAIL)
    .required(messages.REQUIRE_MESSAGE),
  phone: yup
    .string(messages.INCORRECT_SYMBOL)
    .required(messages.REQUIRE_MESSAGE),
  resume: yup.string(messages.INCORRECT_SYMBOL),
});
