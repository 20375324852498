import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "../../assets/icons/Close";
import { SDialog } from "./styles";

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon width="12" height="12" />
        </IconButton>
      ) : null}
      <DialogTitle {...other}>{children}</DialogTitle>
    </>
  );
};

export default function ModalWindow({
  modalTitle,
  children,
  open,
  handleClose,
}) {
  return (
    <div>
      <SDialog onClose={handleClose} open={open}>
        <CustomDialogTitle onClose={handleClose}>
          {modalTitle}
        </CustomDialogTitle>
        <DialogContent>{children}</DialogContent>
      </SDialog>
    </div>
  );
}
