import React, { useState } from "react";
import { Formik, Field } from "formik";
import { schemaResponseVacancy } from "./schema";
import ModalWindow from "../modalWindow";
import CustomInput from "../input";
import {
  SAddResume,
  SBtnSend,
  SBtnUpload,
  SNameEmailWrapper,
  SResponseVacancyForm,
} from "./styles";
import Upload from "../../assets/icons/Upload";
import Successfully from "../../assets/icons/Successfully";
import { useCreateVacancyRespondMutation } from "../../graphql/hooks/useMutations";
import ModalSuccess from "../modals/ModalSuccess";
import ModalError from "../modals/ModalError";

const ResponseVacancyForm = ({ open, handleClose, modalTitle }) => {
  const [createVacancyRespond] = useCreateVacancyRespondMutation();
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorText, setErrorText] = useState('');

  const handleSendResume = (values, { resetForm }) => {
    let dataFields = {
      name: values.name,
      email: values.email,
      phone: values.phone,
    };

    if (values.resume) {
      dataFields = {
        ...dataFields, resume: values.resume
      }
    }

    createVacancyRespond({
      variables: {
        data: dataFields,
      },
    }).then(response => {
      if (response?.data?.createVacancyRespond?.data?.id) {
        handleClose()
        setIsOpenSuccessModal(true)
        resetForm()
      }
      if (response?.errors?.[0]?.message) {
        handleClose()
        setErrorText(response?.errors?.[0]?.message)
        setIsOpenErrorModal(true)
      }
    }).catch((error) => {
      if (error?.message) {
        handleClose()
        setErrorText(error?.message)
        setIsOpenErrorModal(true)
      }
    })
  };

  return (
      <>
        <ModalWindow
            open={open}
            handleClose={handleClose}
            modalTitle={modalTitle}
        >
          <Formik
              initialValues={{ name: "", email: "", phone: "", resume: "" }}
              validationSchema={schemaResponseVacancy}
              onSubmit={handleSendResume}
          >
            {({ setFieldValue, values }) => {
              return (
                  <SResponseVacancyForm noValidate>
                    <SNameEmailWrapper>
                      <Field name="name">
                        {({ field, meta }) => {
                          return (
                              <CustomInput
                                  placeholder="Name"
                                  touched={meta.touched}
                                  error={meta.error}
                                  {...field}
                              />
                          );
                        }}
                      </Field>
                      <Field name="email">
                        {({ field, meta }) => {
                          return (
                              <CustomInput
                                  inputType="email"
                                  placeholder="Email"
                                  touched={meta.touched}
                                  error={meta.error}
                                  {...field}
                              />
                          );
                        }}
                      </Field>
                    </SNameEmailWrapper>
                    <Field name="phone">
                      {({ field, meta }) => {
                        return (
                            <CustomInput
                                inputType="tel"
                                placeholder="Phone"
                                touched={meta.touched}
                                error={meta.error}
                                {...field}
                                inputWrapperWidth="inputPhone"
                            />
                        );
                      }}
                    </Field>
                    <SAddResume>Add resume</SAddResume>
                    <SBtnUpload variant="contained" component="label">
                      {values.resume ? values.resume?.name : "Upload File"}
                      <Upload />
                      <input
                          type="file"
                          onChange={async (event) => {
                            const formData = new FormData();
                            formData.set('files', event.target.files[0])
                            formData.set('ref', 'api::vacancy-respond.vacancy-respond')
                            formData.set('refId', '1')
                            formData.set('field', 'resume')
                            try {
                              const res = await fetch('/api/api/upload', {
                                method: 'post',
                                body: formData
                              });
                              const data = await res.json();
                              setFieldValue("resume", data[0]?.id);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                          hidden
                      />
                    </SBtnUpload>
                    <div>
                      <SBtnSend type="submit">Send resume</SBtnSend>
                    </div>
                  </SResponseVacancyForm>
              );
            }}
          </Formik>
        </ModalWindow>

        <ModalSuccess isOpenSuccessModal={isOpenSuccessModal} setIsOpenSuccessModal={setIsOpenSuccessModal} />
        <ModalError errorText={errorText} isOpenErrorModal={isOpenErrorModal} setIsOpenErrorModal={setIsOpenErrorModal} />
      </>
  );
};

export default ResponseVacancyForm;
