import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Seo from "../components/seo";
import {
  SBtnRespond,
  SDescriptionContainer,
  SMainMargin,
  SSubtitle,
  STitle,
  SVacancyContainer,
  SVacancyContent,
  SVacancySubtitle,
  SVacancyWrapper,
  SWorkplaceFragment,
} from "../styles/career";
import Typography from "@mui/material/Typography";
import ListKeyTechnologies from "../components/listKeyTechnologies";
import ResponseVacancyForm from "../components/forms/ResponseVacancyForm";

const Vacancy = ({
  data: {
    strapi: { vacancy },
  },
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleClickCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Layout>
      <Seo
          title={vacancy?.data?.attributes?.seo?.metaTitle}
          description={vacancy?.data?.attributes?.seo?.metaDescription}
          image={vacancy?.data?.attributes?.seo?.metaImage?.data?.attributes?.url}
      />
      <div className="main-padding">
        <div className="main-wrapper">
          <SVacancyWrapper>
            <Typography variant="h2" component="h1">
              {vacancy?.data?.attributes?.profession}
            </Typography>
            <SVacancyContainer>
              <SVacancyContent>
                <div>
                  <STitle>English level</STitle>
                  <SSubtitle>
                    {vacancy?.data?.attributes?.englishLevel}
                  </SSubtitle>
                </div>
                <div>
                  <STitle>Principle</STitle>
                  <SSubtitle>{vacancy?.data?.attributes?.principle}</SSubtitle>
                </div>
              </SVacancyContent>
              <SBtnRespond type="submit" onClick={handleClickOpenModal}>
                Respond
              </SBtnRespond>
            </SVacancyContainer>
            <hr className="divider-mobile" />
          </SVacancyWrapper>
          {/* DESCRIPTION TOP SECTION */}
          <SDescriptionContainer>
            <SMainMargin>
              <SVacancySubtitle>
                {vacancy?.data?.attributes?.shortDescription?.title}
              </SVacancySubtitle>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    vacancy?.data?.attributes?.shortDescription?.description,
                }}
              />
            </SMainMargin>
            <SMainMargin>
              <SVacancySubtitle>List of key technologies</SVacancySubtitle>
              <ListKeyTechnologies technologiesList={vacancy?.data?.attributes?.technologiesList} />
            </SMainMargin>
          </SDescriptionContainer>
          {/* DESCRIPTION TOP SECTION */}

          {/* OUR ADVANTAGES SECTION */}
          <SMainMargin>
            <SVacancySubtitle
                dangerouslySetInnerHTML={{
                  __html: vacancy?.data?.attributes?.advantages?.title,
                }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: vacancy?.data?.attributes?.advantages?.description,
              }}
            />
          </SMainMargin>
          {/* END OF OUR ADVANTAGES SECTION */}

          {/* WISHES FOR THE WORKPLACE SECTION */}
          <SMainMargin>
            <SVacancySubtitle
                dangerouslySetInnerHTML={{
                  __html: vacancy?.data?.attributes?.workplace?.title,
                }}
            />
            <SWorkplaceFragment
              dangerouslySetInnerHTML={{
                __html: vacancy?.data?.attributes?.workplace?.description,
              }}
            />
          </SMainMargin>
          {/* END OF WISHES FOR THE WORKPLACE SECTION */}
        </div>
        <hr className="divider-mobile" />
      </div>
      <ResponseVacancyForm
        open={openModal}
        handleClose={handleClickCloseModal}
        modalTitle={vacancy?.data?.attributes?.profession}
      />
    </Layout>
  );
};

export default Vacancy;

export const query = graphql`
  query vacancyQuery($id: ID!) {
    strapi {
      vacancy(id: $id) {
        data {
          id
          attributes {
            seo {
              ...seo
            }
            slug
            profession
            englishLevel
            principle
            shortDescription {
              title
              description
            }
            advantages {
              title
              description
            }
            workplace {
              title
              description
            }
            
            technologiesList {
              data {
                id
                attributes {
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;
